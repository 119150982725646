import React, { useState } from 'react';
import { createContact } from '../../services/contact'
import styled from 'styled-components'
import image2 from "../../assets/github.png"
import image3 from "../../assets/linkedin.png"

const ContactStyling = styled.div`
html {
  height: -webkit-fill-available;
}
#contact {
  min-height: 93vh;
  /* min-height: -webkit-fill-available; */
}
.ls-buttons {
    height: 50px;
    width: 150px;
    /* border: 2px solid #96f5e8; */
    border: 2px solid #228b22;
    border-radius: 10px;
    font-weight: bold;
    font-size: 21px;
    outline: none;
    display: block;
    margin: auto;
    margin-top:40px;
    background-color: aliceblue; 
  }
  .ls-buttons:hover{
    background-color:#DCDCDC;

}
h1{
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0.67em;
    font-family: 'Jost', sans-serif;
    font-size: xxx-large;
    text-align:center;
    /* padding-bottom: 2%; */
}
  .create-form {
    background-color: CEE0DC;
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    /* margin-bottom: 60px; */
    max-width: 600px;
    margin: auto;
    margin-top: 5px;
  }

  .input-image-link {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    /* padding: 30px; */
    /* text-align: center; */
    font-size: large;
  }

  .postBody {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    /* padding: 30px; */
    /* text-align: center; */
    font-size: x-large;
    font-size: x-large;
    font-family: inherit;
    resize: none;
  }

  .confirmed{
    text-align: center;
    font-size: xx-large;
    margin-top: 200px;
    margin-bottom: 100px;
  }

  .links{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
  #github{
    max-width: 40px;
    display: flex;
    margin-top: 6px;
  }
  #linkedIn{
    max-width: 53.5px;
    display: flex;
  }
  a:hover{
    transform: scale(1.09);
}
  @media screen and (max-width:990px) {
  .postBody{
    /* margin-bottom: 50px; */
    font-size: large;
    font-family: inherit;
    resize: none;
  }
}
`

function Contact() {
  const [isCreated, setCreated] = useState(false)
  const [newContact, setNewContact] = useState({
    name: '',
    email: '',
    message: ''
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    const submitted = console.log('Submitted....')
    setCreated({ submitted })
    const created = await createContact(newContact)
    setCreated({ created })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setNewContact({
      ...newContact,
      [name]: value
    })
  }

  return (
    <ContactStyling>
      <div id='contact'>
        <h1 className="aboutMe">Contact Me...</h1>
        {!isCreated ?
          <form className="create-form" onSubmit={handleSubmit}>
            <p className='input-title'>Your Name</p>
            <input
              className="input-image-link"
              value={newContact.name}
              name='name'
              required
              onChange={handleChange}
            />
            <p className='input-title'>Your Email</p>
            <input
              className="input-image-link"
              value={newContact.email}
              name='email'
              required
              onChange={handleChange}
            />
            <p className='input-title'>Your Message</p>
            <textarea
              className="postBody"
              rows={6}
              value={newContact.message}
              name='message'
              onChange={handleChange}
            />

            <button type='submit' className="ls-buttons"
            >Submit</button>
          </form>
          : <div>
            <p className="confirmed">Looking forward to connecting!</p>
            <div className="links">
              <a href="https://github.com/ccano2011"
                target="_blank" rel="noopener noreferrer" ><img id="github" src={image2} alt="github"></img></a>
              <a href="https://www.linkedin.com/in/cacano/"
                target="_blank" rel="noopener noreferrer" ><img id="linkedIn" src={image3} alt="github"></img></a>
            </div>
          </div>
        }
      </div>
    </ContactStyling>
  );
}

export default Contact;