import React from 'react';
import styled from 'styled-components';
import { Link as LinkScroll } from 'react-scroll' //This is an alias to workaround { Link } declarations


const HomeStyling = styled.div`
@media screen and (min-width:1440px) {
    .home-description{
        display: flex;
        flex-direction: column;
        max-width: 500px;
        font-family: 'Jost', sans-serif; 
        font-weight: 700;
        font-size: 40px;
    }
}
    #home{
        background: linear-gradient(#000,#000) no-repeat bottom/0.5px 90%;
        padding-top: 18vh;
        padding-bottom: 23vh;
        display:flex;
        justify-content: space-evenly;
    }

    .homeTitle {
        font-family: 'Jost', sans-serif;
        font-weight: 700;
        font-size: 85px;
        max-width: min-content;
        text-align: center;
        -webkit-animation: name-fadein 2s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: name-fadein 2s; /* Firefox < 16 */
        animation: name-fadein 2s;
    }
    .home-description{
        max-width: 400px;
        font-family: 'Jost', sans-serif;
        font-weight: 700;
        font-size: 40px;
        margin-top:130px;
        -webkit-animation: description-fadein 2s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: description-fadein 2s; /* Firefox < 16 */
        animation: description-fadein 2s;
    }

    @keyframes name-fadein {
        from {
        opacity: 0; 
        margin-right: 30%;
        }
        to   { 
            opacity: 1; 
            margin-right:0
        }
    }
    @keyframes description-fadein {
        from {
        opacity: 0; 
        }
        to   { 
            opacity: 1; 
        }
    }

    @media screen and (max-width:1150px) {
        #home{
            background: none;
            padding-top: 18vh;
            padding-bottom: 23vh;
            display: flex;
            flex-direction: column;
            text-align: center;
            animation-name: description-fadein;
            animation-fill-mode: forwards;
            animation-duration: 2s;
            }
        .downArrowBounce{
            padding-top:80px;
        }
        .homeTitle {
            font-family: 'Jost', sans-serif;
            font-size: 85px;
            font-size: 100px;
            max-width: fit-content;
            display: contents;            
            margin-bottom: 0;
        }
        #name {
            padding-bottom: 0;
            margin-bottom: 0;
        }
        .home-description {
            margin-top: 0;
            max-width: fit-content;
            display: contents;
            font-size: x-large;
        }
    }
    @media screen and (max-width:835px) {
    #home{
        background: none;
        display: flex;
        flex-direction: column;
        padding-top: 12vh;
        padding-bottom: 25%;
        animation-name: description-fadein;
        animation-fill-mode: forwards;
        animation-duration: 2s;
    }
    .homeTitle{
        margin: auto;
        display: flex;
        justify-content: center;
        font-size: 55px;
        animation-name: description-fadein;
        animation-fill-mode: forwards;
        animation-duration: 2s;
    }
    .homeInfo{
        margin: auto;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
    }

    }`
const NavLinks = styled(LinkScroll)`
    .arrowImg{
        animation-name: description-fadein;
        animation-fill-mode: forwards;
        animation-delay:2s;
        animation-duration: 2s;
        opacity:0;
    }
    .downArrowBounce{
        display:flex;
        justify-content:center;
        padding-top: 30px;
        opacity:1;
        -moz-animation: bounce 3s infinite;
        -webkit-animation: bounce 3s infinite;
        animation: bounce 3s infinite ;
    }
    /* The following was from Codeconvey.com */
    @-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        transform: translateY(-15px);
    }
    }
    @-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
    }
    @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
    }
    `

function Home() {
    return (
        <HomeStyling id='/'>
            <div id="home">
                <div className="homeTitle">
                    <p id="name" >Christopher Cano</p>
                </div>
                <div className="homeInfo">
                    <p className="home-description">Software Engineer / Full-Stack Developer</p>
                    <NavLinks to='about' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                        <div className="downArrowBounce">
                            <img className='arrowImg' width="40" height="40" alt="arrow" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSLQodC70L7QuV8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yNC4yODUsMTEuMjg0TDE2LDE5LjU3MWwtOC4yODUtOC4yODhjLTAuMzk1LTAuMzk1LTEuMDM0LTAuMzk1LTEuNDI5LDAgIGMtMC4zOTQsMC4zOTUtMC4zOTQsMS4wMzUsMCwxLjQzbDguOTk5LDkuMDAybDAsMGwwLDBjMC4zOTQsMC4zOTUsMS4wMzQsMC4zOTUsMS40MjgsMGw4Ljk5OS05LjAwMiAgYzAuMzk0LTAuMzk1LDAuMzk0LTEuMDM2LDAtMS40MzFDMjUuMzE5LDEwLjg4OSwyNC42NzksMTAuODg5LDI0LjI4NSwxMS4yODR6IiBmaWxsPSIjMTIxMzEzIiBpZD0iRXhwYW5kX01vcmUiLz48Zy8+PGcvPjxnLz48Zy8+PGcvPjxnLz48L3N2Zz4=" />
                        </div>
                    </NavLinks>
                </div>
            </div>
        </HomeStyling>
    );
}

export default Home;