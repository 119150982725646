import React, { useRef, useEffect } from 'react';
import styled from 'styled-components'
import image from '../../assets/p1.png'
import image2 from '../../assets/p2.png'
import image3 from '../../assets/p3.png'
import image4 from '../../assets/p4.png'
import image5 from '../../assets/image5.png'
import image6 from '../../assets/project6.png'
import image7 from '../../assets/project7.png'
import image8 from '../../assets/jordanwedding.jpeg'
import image9 from '../../assets/project8.png'

const ProjectsStyling = styled.div`
h1{
    margin-top: 0;
    padding-top: 0.67em;
    font-family: 'Jost', sans-serif;
    font-size: xxx-large;
    text-align:center;
    margin-bottom:auto;
}

.projects {
    display:flex;
    flex-wrap: nowrap;
    overflow-x: auto !important;
    max-width:1920px;
    margin:auto;
  }
  .projects-box{
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .projects-box {
  &::-webkit-scrollbar {
    display: none;
  }
}

a{
    text-decoration: none;
    color: inherit;
    font-weight:bolder;
    transition: all .2s ease-in-out; 
}

.project-imgs {
  max-width: 300px;
  max-height:261px;
  text-align:center;
  height: auto;
  }

  .project-content {
    text-align: center;
    margin: 20px;
    max-width: fit-content;
    display:flex;
  }

  .card-content {
    border: 1px solid black;
  }

  .text-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }

  .projects-name {
    font-family: 'Jost', sans-serif;
  }

  .projects-description {
    font-size: 12px;
    line-height: 1.6;
  }

  .built-with{
    font-size:9px;
  }

  .project-buttons {
    display: flex;
    justify-content: space-evenly;
    align-items:flex-end;
  }

  .gh-buttons:hover {
    background-color:#DCDCDC;
  }
  .ls-buttons:hover{
    background-color:#DCDCDC;
}
a:hover{
    transform: scale(1.09);
}
  .ls-buttons {
    height: 30px;
    font-family: 'Jost', sans-serif;
    width: 100px;
    border: 2px solid #228b22;
    /* border: 2px solid #96f5e8; */
    border-radius: 10px;
    font-weight: bold;
    font-size: 15px;
    outline: none;
    background-color: aliceblue; 
  }

  .gh-buttons {
    height: 30px;
    width: 90px;
    font-family: 'Jost', sans-serif;
    border: 2px solid #228b22;
    /* border: 2px solid #96f5e8; */
    border-radius: 10px;
    font-weight: bold;
    font-size: 15px;
    outline: none;
    background-color: aliceblue; 
  }


  .container{
      overflow-x:auto;
    transform: translateX(50%);
    transition: opacity 250ms ease-in, -webkit-transform 400ms ease-in;
    transition: opacity 250ms ease-in, transform 400ms ease-in;
    transition: opacity 250ms ease-in, transform 400ms ease-in,
    -webkit-transform 400ms ease-in;
    opacity: 0;
  }

  .container.appear {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
`

function Projects() {
    const elemRef = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // console.log(entry);
                if (entry.isIntersecting) {
                    entry.target.classList.add("appear");
                }
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0.2
            }
        );
        if (elemRef.current) {
            observer.observe(elemRef.current);
        }
    }, [elemRef]);
    return (
        <ProjectsStyling>
            <div id='projects'>
                <h1>Projects</h1>
                <div className="projects-box">
                    <div ref={elemRef} className="container">
                        <div className="projects">
                            <div className="project-content">
                                <div className="card-content">
                                    <img
                                        className="project-imgs"
                                        src={image9}
                                        alt="project png"
                                    />
                                    <div className="text-content">
                                        <h2 className="projects-name">Seefeldt Wedding</h2>
                                        <div className="project-buttons">
                                            <a href="http://canoseefeldtwedding.com/" target="_blank" rel="noopener noreferrer" ><button className="ls-buttons">Visit Site</button></a>
                                            {/* <a href="https://github.com/ccano2011/wedding-app" target="_blank" rel="noopener noreferrer"><button className="gh-buttons">GitHub</button></a> */}
                                        </div>
                                        <p className="projects-description">A multi-page, multi-component Full-Stack web app tailor made for a client's wedding.</p><p className="built-with">Built using Ruby on Rails, PostgreSQL, & React.js</p>
                                    </div>
                                </div>
                            </div>
                            <div className="project-content">
                                <div className="card-content">
                                    <img
                                        className="project-imgs"
                                        src={image8}
                                        alt="project png"
                                    />
                                    <div className="text-content">
                                        <h2 className="projects-name">Nichols Wedding</h2>
                                        <div className="project-buttons">
                                            <a href="http://quack-mitten.surge.sh/" target="_blank" rel="noopener noreferrer" ><button className="ls-buttons">Visit Site</button></a>
                                            {/* <a href="https://nichols-wedding.netlify.app" target="_blank" rel="noopener noreferrer" ><button className="ls-buttons">Visit Site</button></a> */}
                                            {/* <a href="https://github.com/ccano2011/wedding-app" target="_blank" rel="noopener noreferrer"><button className="gh-buttons">GitHub</button></a> */}
                                        </div>
                                        <p className="projects-description">A single-page, multi-component Full-Stack web app tailor made for a client's wedding.</p><p className="built-with">Built using Ruby on Rails, PostgreSQL, & React.js</p>
                                    </div>
                                </div>
                            </div>
                            <div className="project-content">
                                <div className="card-content">
                                    <img
                                        className="project-imgs"
                                        src={image4}
                                        alt="project png"
                                    />
                                    <div className="text-content">
                                        <h2 className="projects-name">Happily Ever After v2.0</h2>
                                        <div className="project-buttons">
                                            <a href="https://happily-ever-after-wedding-app.netlify.app" target="_blank" rel="noopener noreferrer" ><button className="ls-buttons">Visit Site</button></a>
                                            <a href="https://github.com/ccano2011/wedding-app" target="_blank" rel="noopener noreferrer"><button className="gh-buttons">GitHub</button></a>
                                        </div>
                                        <p className="projects-description">With a completely new frontend, this is more than just a regular wedding site, this application incorporates features found on many popular social media sites that allow for guests to sign up with full authentication and make posts on the site's 'Corkboard' component. </p><p className="built-with">Built using Ruby on Rails, PostgreSQL, & React.js</p>
                                    </div>
                                </div>
                            </div>
                            <div className="project-content">
                                <div className="card-content">
                                    <img
                                        className="project-imgs"
                                        src={image6}
                                        alt="project png"
                                    />
                                    <div className="text-content">
                                        <h2 className="projects-name">Another Netflix Clone</h2>
                                        <div className="project-buttons">
                                            <a href="https://another-netflix-clone.netlify.app/" target="_blank" rel="noopener noreferrer" ><button className="ls-buttons">Visit Site</button></a>
                                            <a href="https://github.com/ccano2011/another-netflix-clone" target="_blank" rel="noopener noreferrer"><button className="gh-buttons">GitHub</button></a>
                                        </div>
                                        <p className="projects-description">This single-page app was made to look and feel like the homepage of Netflix complete with pop-up trailers utilizing <a href="https://www.npmjs.com/package/react-youtube" target="_blank" rel="noreferrer" alt="link to npm youtube react">React-YouTube</a> to handle embedded videos.</p><p className="built-with">Built using React.js, React-YouTube, & Styled Components</p>
                                    </div>
                                </div>
                            </div>
                            <div className="project-content">
                                <div className="card-content">
                                    <img
                                        className="project-imgs"
                                        src={image7}
                                        alt="project png"
                                    />
                                    <div className="text-content">
                                        <h2 className="projects-name">e-Commerce</h2>
                                        <div className="project-buttons">
                                            <a href="https://e-commerce-cano.netlify.app/" target="_blank" rel="noopener noreferrer" ><button className="ls-buttons">Visit Site</button></a>
                                            <a href="https://github.com/ccano2011/e-Commerce" target="_blank" rel="noopener noreferrer"><button className="gh-buttons">GitHub</button></a>
                                        </div>
                                        <p className="projects-description">A multi-component single-page React application made as a template for any commerse/front-end project</p><p className="built-with">Built using React.js, React-Scroll, & Styled Components</p>
                                    </div>
                                </div>
                            </div>
                            <div className="project-content">
                                <div className="card-content">
                                    <img
                                        className="project-imgs"
                                        src={image5}
                                        alt="project png"
                                    />
                                    <div className="text-content">
                                        <h2 className="projects-name">loopstudios</h2>
                                        <div className="project-buttons">
                                            <a href="https://loopstudios-cano.netlify.app/" target="_blank" rel="noopener noreferrer" ><button className="ls-buttons">Visit Site</button></a>
                                            <a href="https://github.com/ccano2011/loopstudios" target="_blank" rel="noopener noreferrer"><button className="gh-buttons">GitHub</button></a>
                                        </div>
                                        <p className="projects-description">This project is a simple front-end landing site built as a coding challenge from <a href="https://www.frontendmentor.io/challenges" alt="link to frontend mentor .io" target="_blank" rel="noreferrer">Frontend Mentor</a> for the purpose of learning Styled Components & improve coding habits.</p><p className="built-with">Built using React.js & Styled Components</p>
                                    </div>
                                </div>
                            </div>
                            <div className="project-content">
                                <div className="card-content">
                                    <img
                                        className="project-imgs"
                                        src={image2}
                                        alt="project png"
                                    />
                                    <div className="text-content">
                                        <h2 className="projects-name">Movie Quest</h2>
                                        <div className="project-buttons">
                                            <a href="https://movie-quest.netlify.app/" target="_blank" rel="noopener noreferrer" ><button className="ls-buttons">Visit Site</button></a>
                                            <a href="https://github.com/ccano2011/Movie-Quest" target="_blank" rel="noopener noreferrer"><button className="gh-buttons">GitHub</button></a>
                                        </div>
                                        <p className="projects-description">Movie Quest is a web application that allows users to see what movies are popular right now, find a movie to watch by checking out what what's popular, and find recommendations based on genre & user rating. </p><p className="built-with">This was built with React.js & uses Airtable to save movies to a list</p>
                                    </div>
                                </div>

                            </div>
                            <div className="project-content">
                                <div className="card-content">
                                    <img
                                        className="project-imgs"
                                        src={image3}
                                        alt="project png"
                                    />
                                    <div className="text-content">
                                        <h2 className="projects-name">Elections App</h2>
                                        <div className="project-buttons">
                                            {/* <a href="https://ballot-request-elections-app.netlify.app" target="_blank" rel="noopener noreferrer" ><button className="ls-buttons">Visit Site</button></a> */}
                                            <a href="https://github.com/ccano2011/election-app" target="_blank" rel="noopener noreferrer"><button className="gh-buttons">GitHub</button></a>
                                        </div>
                                        <p className="projects-description">This proof-of-concept project was a collaborative effort involving several developers and a professional UX design team. The purpose of this Full-Stack app was to show how one could request an absentee ballot & check the delivery status of their ballot request for their home state during an election.</p><p className="built-with">Built using React, Express, & MongoDB </p>
                                    </div>
                                </div>
                            </div>
                            <div className="project-content">
                                <div className="card-content">
                                    <img
                                        className="project-imgs"
                                        src={image}
                                        alt="project png"
                                    />
                                    <div className="text-content">
                                        <h2 className="projects-name">Pokedex</h2>
                                        <div className="project-buttons">
                                            <a href="https://ccano2011.github.io/Pokedex/" target="_blank" rel="noopener noreferrer"><button className="ls-buttons">Visit Site</button></a>
                                            <a href="https://github.com/ccano2011/Pokedex" target="_blank" rel="noopener noreferrer"><button className="gh-buttons">GitHub</button></a>
                                        </div>
                                        <p className="projects-description">Pokedex leverages the <a href="https://pokeapi.co/" target="_blank" rel="noopener noreferrer">PokeAPI</a> to let you search and find each and every Pokemon discovered thus far from every region to help you Catch 'em All!</p><p className="built-with"> Built using JavaScript
                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </ProjectsStyling >
    );
}

export default Projects;