import React, { useState } from 'react';
import './App.css';
// import Blog from './screens/Blog/Blog';
// import Layout from './layouts/Layout';
import Home from './screens/Home/Home';
import AboutMe from './screens/AboutMe/AboutMe'
import Projects from './screens/Projects/Projects'
import Contact from './screens/Contact/Contact'
import MobileNav from './shared/MobileNav';
import Nav from './shared/nav.jsx'

function App() {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <MobileNav isOpen={isOpen} toggle={toggle} />
      <Nav toggle={toggle} />
      <Home />
      <AboutMe />
      <Projects />
      {/* <Blog /> */}
      <Contact />
    </>
  );
}

export default App;
