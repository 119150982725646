import React, { useState, useEffect } from 'react';
import { Link as LinkScroll } from 'react-scroll' //This is an alias to workaround { Link } declarations
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa'


const NavComponent = styled.nav`
    background: ${({ scrollNav }) => (scrollNav ? '#fffaf0' : 'transparent')};
    position:sticky;
    height:60px;
    display:flex;
    justify-content: center;
    align-items:center;
    font-size:1rem;
    top:0;
    z-index:10;
    transition:0.8s all ease;
    @media screen and (max-width:960px) {
        height:70px;
    }
`;
const NavbarContainer = styled.div`
    display:flex;
    justify-content:space-between;
    z-index:1;
    width: 100%;
    padding:0 24px;
    padding-top: 0px;
`;
const MobileIcon = styled.div`
display:none;
@media screen and (max-width:768px){
    display:block;
    position:absolute;
    top:0;
    right:0;
    transform: translate(-100%,60%);
    font-size: 1.8rem;
    cursor:pointer;
    color:black;
}
`;
const NavMenu = styled.ul`
    display:flex;
    align-items:center;
    list-style:none;
    text-align:center;
    margin-right:-22px;
    @media screen and (max-width:768px){
    display:none;
}
`;

const NavItem = styled.li`
height:80px;
`;

const NavLinks = styled(LinkScroll)`
font-family: 'Jost', sans-serif;
font-weight:700;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height:100%;
cursor: pointer;
&.active{
    /* border-bottom: 3px solid #01bf71; */
    border-bottom: 3px solid #228b22
}
`;

function Nav({ toggle }) {
    const [scrollNav, setScrollNav] = useState(false)
    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])


    return (
        <>
            <NavComponent scrollNav={scrollNav}>
                <NavbarContainer>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to='/'
                                smooth={true} duration={500} spy={true} exact='true' offset={-80}>Home</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='about' smooth={true} duration={500} spy={true} exact='true' offset={-80}>About</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='projects' smooth={true} duration={500} spy={true} exact='true' offset={-80}>Projects</NavLinks>
                        </NavItem>
                        {/* <NavItem>
                            <NavLinks to='blog' smooth={true} duration={500} spy={true} exact='true' offset={-80}>Journal</NavLinks>
                        </NavItem> */}
                        <NavItem>
                            <NavLinks to='contact' smooth={true} duration={500} spy={true} exact='true' offset={-80}>Contact</NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
                <hr />
            </NavComponent>
        </>
    );
}
export default Nav;