import React, { useEffect, useRef } from 'react';
import styled from 'styled-components'
import pic1 from "../../assets/chris-cafe.jpg"
import pic2 from "../../assets/chris-beach.jpg"
import pic3 from "../../assets/chris-spain.JPG"
import pic4 from "../../assets/chris-italy.jpg"
import image2 from "../../assets/github.png"
import image3 from "../../assets/linkedin.png"

const AboutMeStyling = styled.div`
.aboutMe {
    font-family: 'Jost', sans-serif;
    font-size: xxx-large;
    @media screen and (max-width:768px) {
    text-align:center;
    }
}
h2 {
  @media screen and (max-width:768px) {
    text-align:center;
    }
}

.ls-buttons {
    height: 50px;
    width: 150px;
    /* border: 2px solid #96f5e8; */
    border: 2px solid #228b22;
    border-radius: 10px;
    font-weight: bold;
    font-size: 21px;
    outline: none;
    display: block;
    margin: auto;
    margin-top:40px;
    background-color: aliceblue; 
  }
  .ls-buttons:hover{
    background-color:#DCDCDC;
    /* transform: scale(1.09); */
}
a:hover{
    transform: scale(1.09);
}
.links{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
  #github{
    max-width: 40px;
    display: flex;
    margin-top: 6px;
  }
  #linkedIn{
    max-width: 53.5px;
    display: flex;
  }
.photos{
    width: 300px;
    height:450px;
}
#home2{
    background: linear-gradient(#000, #000) no-repeat center/0.5px 100%;
    max-width: 1017px;
    margin:auto;
    padding-bottom: 9%;
    display: flex;
    justify-content: space-around;
    opacity: 0;
    transition: opacity .5s ease-in;
}

#home2.appear {
  opacity: 1;
}
  .missionStatement {
    display: flex;
    max-width: 1280px;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
  }

  .missionStatementTitle{
    max-width: 400px;
    margin-left: 300px;
    margin-right: 1.5%;
    line-height: 1.6;
  }

  #stage {
    margin-left: 9.5%;
    margin-top: -180px;
    width: 300px;
    height:400px;
  }

  #stage img {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5%;
    background: whitesmoke;
    animation-name: fade; 
    animation-duration: 40s; 
    animation-iteration-count: infinite; 
    position:absolute;
    opacity:0; 
  }

  #stage img:nth-of-type(1) {
    animation-delay: 0s;
  }
  #stage img:nth-of-type(2) {
    animation-delay: 10s;
  }
  #stage img:nth-of-type(3) {
    animation-delay: 20s;
  }
  #stage img:nth-of-type(4) {
    animation-delay: 30s;
  }

  @keyframes fade {
    0%   { opacity: 0; }
    11.11%   { opacity: 1; }
    33.33%  { opacity: 1; }
    44.44%  { opacity: 0; }
    100% { opacity: 0; }
  }

.company-name{
  font-weight: bold;
  text-decoration: none;
  color: inherit;
}
/* .company-name:hover{
    color:#228b22;
} */
@media screen and (max-width:1048px) {
    #home2{
        background: none;
        display: flex;
        flex-direction: column;
        padding-top: 3vh;
    }
    #stage{
        height: 292px;
        margin-left: 0;
        display: flex;
        margin-top: 0;
        margin-right: 2%;
        justify-content: center;
    }
    .photos{
        width: 200px;
        height:300px;
    }
    .missionStatement {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        max-width: 1280px;
        margin-top: auto;
        align-items: center;
        -ms-flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-box-align: center;
        -ms-flex-align: center;
    }
    .missionStatementTitle{
        max-width: 567px;
        margin-left: 5%;
        margin-right: 0;
        margin-top: 40px;
        line-height: 1.8;
    }
    .ls-buttons {
        height: 50px;
        width: 150px;
        /* border: 2px solid #96f5e8; */
        border: 2px solid #228b22;

        border-radius: 10px;
        font-weight: bold;
        font-size: 21px;
        outline: none;
        margin-top: 40px;
        margin-left: 0;
    }
    .links{
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-top: -59px;
        justify-content: flex-start;
        margin-left: 175px;
    }
    #github{
        max-width: 53px;
        display: flex;
        margin-top: 8px;
        margin-left: 45px;
    }
  #linkedIn{
    max-width: 70px;
    display: flex;
  }
}

@media screen and (max-width:836px) {
   .missionStatement {
        display: flex;
        margin-top: 80px;
        flex-direction: column;
}
    .missionStatementTitle {
        max-width: 567px;
        margin-left: 15px;
        margin-right: 15px;
        width: auto;
        margin-top: 0;
        line-height: 1.8;
}
    #stage {
        height: 292px;
        margin-left:0;
        display: flex;
        width: auto;
        margin: auto;
        justify-content: center;
    }
    .ls-buttons{
        margin: auto;
        margin-left:auto;
        margin-top: 40px;
    }
    .links {
        display: flex;
        margin-top: 15px;
        justify-content: center;
        margin-left: 0;
    }
#github{
        max-width: 53px;
        display: flex;
        margin-top: 8px;
        margin-left: 0;
    }
}
`
function AboutMe() {
  const elemRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // console.log(entry);
        if (entry.isIntersecting) {
          entry.target.classList.add("appear");
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.2
      }
    );
    if (elemRef.current) {
      observer.observe(elemRef.current);
    }
  }, [elemRef]);

  return (
    <AboutMeStyling id='about'>
      <div>
        <div ref={elemRef} className="missionStatement" id="home2">
          <div id="stage">
            <img className="photos" src={pic3} alt='' />
            <img className="photos" src={pic2} alt='' />
            <img className="photos" src={pic1} alt='' />
            <img className="photos" src={pic4} alt='' />
          </div>
          <div className='missionStatementTitle'>
            <h1 className="aboutMe">About Me...</h1>
            <p>I am a software engineer transitioning from the medical field to pursue my passion for technology. As someone who’s been described meticulous, driven & hardworking during my time in the medical field, I know will bring that same level of dedication & persistence to software engineering. My passion for problem solving and interest in technology industry fueled my career transition to this space. I enjoy and value research, evident through my background in STEM, and always look for opportunities to learn and grow. In 2021, I founded <span className="company-name" >C. C. Software Designs </span>to bring my consumer-facing experience along with my ever-growing arsenal of development skillsets to provide custom solutions for clients.</p>
            <h2>
              Languages & Frameworks
            </h2>
            <p>HTML, CSS, Javascript, Ruby, React, Node.js, Express, Rails, Mongoose, MongoDB,
              Postgres, & GitHub</p>
            <form >
              <button className="ls-buttons" formAction="https://drive.google.com/file/d/11DQAtymIjyfK0GDpe5PjSjV_oMbefJgP/view?usp=sharing">
                Resume
              </button>
            </form>
            <div className="links">
              <a href="https://www.linkedin.com/in/cacano/"
                target="_blank" rel="noopener noreferrer" ><img id="linkedIn" src={image3} alt="github"></img></a>
              <a href="https://github.com/ccano2011"
                target="_blank" rel="noopener noreferrer" ><img id="github" src={image2} alt="github"></img></a>
            </div>
          </div>
        </div>
      </div>
    </AboutMeStyling >
  );
}

export default AboutMe;
