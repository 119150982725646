import api from './apiConfig';

//  REFERENCE CODE FROM TASTEVILLE

// export const getAllPosts = async () => {
//     const resp = await api.get('/posts');
//     return resp.data;
// }

// export const getOnePost = async (id) => {
//     const resp = await api.get(`/posts/${id}`);
//     return resp.data;
// }

//Is this compatible with the backend association???? Find out next week on Dragonball Z!
// export const createPost = async (postId, userId) => {
//     const resp = await api.put(`/posts/`);
//     return resp.data;
// }

export const createContact = async (data) => {
    const resp = await api.post('/contacts', { contact: data });
    return resp.data;
}

// export const updatePost = async (id, Data) => {
//     const resp = await api.put(`/posts/${id}`, { post: Data });
//     return resp.data;
// }

// export const destroyPost = async (id) => {
//     const resp = await api.delete(`/posts/${id}`);
//     return resp;
// }